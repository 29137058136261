import { format, isSameDay, isWeekend } from "date-fns";
import type { ViewDate } from "./types";
import { Text } from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import { useCurrentViewport } from "../../hooks/use-current-viewport";

const SPECIAL_OCCASION = [
	{
		name: "Julafton",
		emoji: "🎅",
	},
	{
		name: "Påskafton",
		emoji: "🐤",
	},

	{
		name: "Alla helgons dag",
		emoji: "👻",
	},

	{
		name: "Midsommarafton",
		emoji: "🌸",
	},
	{
		name: "Nationaldagen",
		emoji: "🇸🇪",
	},
	{
		name: "Nyårsafton",
		emoji: "🎇",
	},
	{
		name: "Nyårsdagen",
		emoji: "🎆",
	},
];

type IHeaderRow = {
	dateRange: ViewDate[];
};

export const HeaderRow = ({ dateRange }: IHeaderRow) => {
	const { smallScreen } = useCurrentViewport();

	return (
		<>
			<div />
			{dateRange.map(viewDate => {
				const specialOccation = SPECIAL_OCCASION.find(so => viewDate.public_holiday === so.name);
				return (
					<div
						title={specialOccation?.name}
						style={{
							position: "relative",
							paddingTop: "calc(var(--mantine-spacing-xs) / 2)",
							paddingBottom: "calc(var(--mantine-spacing-xs) / 2)",
							textAlign: "center",
							color:
								isWeekend(viewDate.date) || !!viewDate.public_holiday
									? "var(--mantine-color-bright)"
									: "var(--mantine-color-text)",
							background:
								isWeekend(viewDate.date) || !!viewDate.public_holiday
									? "var(--mantine-color-orange-7)"
									: "transparent",
							borderBottom: isSameDay(new Date(), viewDate.date)
								? "2px solid var(--mantine-color-cyan-3)"
								: "2px solid transparent",
						}}
						key={format(viewDate.date, "dd")}
					>
						{viewDate.locked && (
							<IconLock
								color="var(--mantine-color-red-4)"
								style={{
									position: "absolute",
									top: -16,
									left: "50%",
									marginLeft: -6,
								}}
								size="12px"
							/>
						)}
						{specialOccation && (
							<Text
								title={specialOccation.name}
								style={{
									position: "absolute",
									top: -10,
									left: "2%",
								}}
							>
								{specialOccation.emoji}
							</Text>
						)}
						<Text size="xs">{format(viewDate.date, "d")}</Text>
						<Text size="xs">{format(viewDate.date, "E")}</Text>
					</div>
				);
			})}
			{!smallScreen && (
				<>
					<Text px="sm">Total</Text>
					<div />
					<div />
				</>
			)}
		</>
	);
};
