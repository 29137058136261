import { Button, Paper, PasswordInput, Text, TextInput, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../api/auth";
import man from "../static/images/man.svg";
import { useAuth } from "../store/auth";
import classes from "./login.module.css";

export const Login = () => {
	const navigate = useNavigate();
	const {
		state: { isAuthenticated },
		login,
	} = useAuth();

	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

	useEffect(() => {
		if (isAuthenticated) navigate("/");
	}, [isAuthenticated]);

	const handleCredentialsLogin = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		setIsLoggingIn(true);
		getToken(email, password)
			.catch(err => {
				setError(err.message);
				return Promise.reject("No no");
			})
			.then(login)
			.then(() => {
				setError("");
			})
			.finally(() => setIsLoggingIn(false));
	};

	return (
		<div className={classes.wrapper}>
			<Paper className={classes.form} radius={0} p={30}>
				<Title order={1} className={classes.title} ta="center" mt="md" mb={50}>
					<img src={man} alt="Diety" style={{ width: "90px" }} />
					<div>
						Timerep
						<Text
							style={{
								fontSize: "0.8em",
								fontFamily: "Parisienne",
							}}
						>
							Eau De Virki
						</Text>
					</div>
				</Title>

				<form>
					<TextInput
						label="Email"
						value={email}
						onChange={event => setEmail(event.currentTarget.value)}
						placeholder="champ@virki.se"
						size="md"
					/>
					<PasswordInput
						label="Password"
						value={password}
						onChange={event => setPassword(event.currentTarget.value)}
						placeholder="**********"
						mt="md"
						size="md"
					/>
					<Button
						type="submit"
						loading={isLoggingIn}
						fullWidth
						mt="xl"
						onClick={handleCredentialsLogin}
						size="md"
					>
						Login
					</Button>
				</form>

				{error && <Text c="red">{error}</Text>}
			</Paper>
		</div>
	);
};
