import { format } from "date-fns";
import type { AssignedProject, InputChangePassword, OutputChangePassword } from "../generated";
import { API_BASE_URL } from ".";
import { getProjectsFromAssignedProjects } from "./projects";
import { timeFetch } from ".";

export const getMyAssignedProjects = (startDate: Date, endDate: Date) => {
	return timeFetch<Array<AssignedProject>>(
		`${API_BASE_URL}/api/v1/me/assigned_projects?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{
			method: "GET",
		},
	);
};

export const getMyProjects = (startDate: Date, endDate: Date) => {
	return getMyAssignedProjects(startDate, endDate).then(getProjectsFromAssignedProjects);
};

export const updatePassword = (input: InputChangePassword) => {
	return timeFetch<OutputChangePassword>(`${API_BASE_URL}/api/v1/me/update_password`, {
		method: "POST",
		body: JSON.stringify(input),
	});
};
