import { useEffect, useState } from "react";
import type { Contact as APIContact, InputContact, Project } from "../../../generated";
import { Button, Container, Divider, Flex, Group, Input, SimpleGrid } from "@mantine/core";
import { createContact, getContact, getProjectByContactId, updateContact } from "../../../api/contacts";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconArrowLeft, IconEdit } from "@tabler/icons-react";
import { add as addNotification } from "../../../store/notifications";

type IContact = {
	contactId?: string;
};

export const Contact = ({ contactId }: IContact) => {
	const navigate = useNavigate();
	const [contact, setContact] = useState<APIContact>();
	const { customerId, page } = useParams();
	const [projects, setProjects] = useState<Project[]>([]);
	const [editing, setEditing] = useState<boolean>(!contactId);
	const [editValues, setEditValues] = useState<InputContact>({
		customer_id: customerId,
	});

	useEffect(() => {
		if (!contactId) return;

		getContact(contactId).then(setContact);
		getProjectByContactId(contactId).then(setProjects);
	}, [contactId]);

	useEffect(() => {
		if (!contact) return;
		setEditValues({
			name: contact.name,
			email: contact.email,
			phone: contact.phone,
			comment: contact.comment,
			customer_id: contact.customer_id,
		});
	}, [contact]);

	const handleSave = () => {
		if (contactId) {
			updateContact(contactId, editValues)
				.then(setContact)
				.finally(() => {
					setEditing(false);
					addNotification({
						title: "Successfully updated contact",
						message: `Updated ${editValues.name}`,
						lifetime: 3000,
					});
				});
		} else {
			createContact(editValues)
				.then(c => {
					setContact(c);
					navigate(`/customersv2/${customerId}/${page}/${c.id}`);
				})
				.finally(() => {
					setEditing(false);
					addNotification({
						title: "Successfully created contact",
						message: `Created ${editValues.name}`,
						lifetime: 3000,
					});
				});
		}
	};

	return (
		<Container>
			<Flex justify="space-between">
				<Button
					size="compact-md"
					component={Link}
					to={`/customersv2/${customerId}/${page}`}
					variant="light"
					leftSection={<IconArrowLeft size={14} />}
				>
					Contact list
				</Button>
				{editing ? (
					<Group>
						{contactId && (
							<Button
								size="compact-md"
								onClick={() => setEditing(false)}
								variant="light"
								color="red"
								mr="md"
								leftSection={<IconEdit size={14} />}
							>
								Cancel
							</Button>
						)}
						<Button
							size="compact-md"
							onClick={handleSave}
							variant="light"
							color="green"
							leftSection={<IconEdit size={14} />}
						>
							Save
						</Button>
					</Group>
				) : (
					<Button
						size="compact-md"
						onClick={() => setEditing(true)}
						variant="light"
						leftSection={<IconEdit size={14} />}
					>
						Edit
					</Button>
				)}
			</Flex>
			<Divider my="md" />
			<SimpleGrid cols={2}>
				<SimpleGrid cols={2}>
					<span>Name</span>
					{editing || !contact ? (
						<Input
							value={editValues.name}
							onChange={ev =>
								setEditValues({
									...editValues,
									name: ev.target.value,
								})
							}
						/>
					) : (
						<span>{contact.name}</span>
					)}
					<span>Email</span>
					{editing || !contact ? (
						<Input
							value={editValues.email}
							onChange={ev =>
								setEditValues({
									...editValues,
									email: ev.target.value,
								})
							}
						/>
					) : (
						<span>
							<a href={`mailto:${contact.email}`}>{contact.email}</a>
						</span>
					)}
					<span>Phone</span>
					{editing || !contact ? (
						<Input
							value={editValues.phone}
							onChange={ev =>
								setEditValues({
									...editValues,
									phone: ev.target.value,
								})
							}
						/>
					) : (
						<span>
							<a href={`tel:${contact.phone}`}>{contact.phone}</a>
						</span>
					)}
				</SimpleGrid>
				<div>
					<h2>Projects</h2>
					{projects.map(project => (
						<div key={project.id}>
							<Link to={`/customersv2/${customerId}/projects/${project.id}`}>{project.name}</Link>
						</div>
					))}
				</div>
			</SimpleGrid>
		</Container>
	);
};
