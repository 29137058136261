import { Avatar, Group, Menu, Text, UnstyledButton, type UnstyledButtonProps } from "@mantine/core";
import { IconChevronRight, IconLogout, IconSettings } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useAuth } from "../store/auth";
import classes from "./user-button.module.css";

interface UserButtonProps extends UnstyledButtonProps {
	image?: string;
	name: string;
	email?: string;
	icon?: React.ReactNode;
	onClick?: () => void;
}

export function UserButton({ image, name, email, icon, ...others }: UserButtonProps) {
	const { logout } = useAuth();

	return (
		<UnstyledButton className={classes.user} {...others}>
			<Menu position="right" withArrow>
				<Menu.Target>
					<Group>
						<Avatar src={image} radius="xl" />

						<div style={{ flex: 1 }}>
							<Text size="sm" fw={500}>
								{name}
							</Text>

							<Text c="dimmed" size="xs">
								{email}
							</Text>
						</div>

						{icon || <IconChevronRight size={14} stroke={1.5} />}
					</Group>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Label>Application</Menu.Label>
					<Menu.Item component={Link} to={"/settings"} leftSection={<IconSettings size={14} />}>
						Settings
					</Menu.Item>

					<Menu.Divider />
					<Menu.Item onClick={() => logout()} leftSection={<IconLogout size={14} />}>
						Logga ut
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</UnstyledButton>
	);
}
