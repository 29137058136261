import { addMonths, startOfMonth } from "date-fns";
import { ReportLineOverview } from "../../components/report-line-overview";
import { Card, Container, Group } from "@mantine/core";
import { ExpiringAssignments } from "./widgets/expiring-assignments";
import { useAuth } from "../../store/auth";
import { ReportLockReminder } from "./widgets/lock-reminder";

export const UserDashboard = () => {
	const { state } = useAuth();
	const som = addMonths(startOfMonth(new Date()), -1);
	const eom = new Date();

	return (
		<Container
			fluid
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "var(--mantine-spacing-xl)",
			}}
			p="xl"
		>
			<Card withBorder radius="md" padding="xl" bg="var(--mantine-color-body)">
				<ReportLineOverview from={som} to={eom} />
			</Card>
			{state.user?.Groups.includes("admin") && (
				<Group justify="space-between" align="start" grow>
					<ExpiringAssignments />
					<ReportLockReminder />
				</Group>
			)}
		</Container>
	);
};
