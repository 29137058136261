import { Card, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { getExpiringAssignedProjects } from "../../../api/assigned_projects";
import type { AssignedProject, Project, User } from "../../../generated";
import { getUser } from "../../../api/users";
import { getProject } from "../../../api/projects";
import classes from "./expiring-assignments.module.css";
import { IconArrowRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export const ExpiringAssignments = () => {
	const [expiringAssignedProjects, setExpiringAssignedProjects] = useState<AssignedProject[]>([]);
	const [users, setUsers] = useState<User[]>([]);
	const [projects, setProjects] = useState<Project[]>([]);
	useEffect(() => {
		getExpiringAssignedProjects().then(setExpiringAssignedProjects);
	}, []);

	useEffect(() => {
		const missingUserIds = expiringAssignedProjects
			.filter(ap => !users.some(u => u.id === ap.user_id))
			.map(ap => ap.user_id);
		const missingProjectIds = expiringAssignedProjects
			.filter(ap => !projects.some(p => p.id === ap.project_id))
			.map(ap => ap.project_id);

		Promise.all(missingUserIds.map(u => getUser(u))).then(newUsers => setUsers([...users, ...newUsers]));
		Promise.all(missingProjectIds.map(u => getProject(u))).then(newProjects =>
			setProjects([...projects, ...newProjects]),
		);
	}, [expiringAssignedProjects]);

	const items = expiringAssignedProjects.map(item => {
		const project = projects.find(p => p.id === item.project_id);
		const user = users.find(u => u.id === item.user_id);

		return (
			<Link to={`/users/${user?.id}/projects`} className={classes.item} key={item.id}>
				<div>
					<Text>{user?.name}</Text>
					<Text size="xs" c="dimmed">
						{project?.name}
					</Text>
				</div>

				<div>
					<Text>{item.end_date}</Text>
				</div>
				<IconArrowRight />
			</Link>
		);
	});

	return (
		<Card withBorder radius="md" p="xl" className={classes.card}>
			<Text fz="lg" className={classes.title} fw={500}>
				Uppdrag som löper ut snart
			</Text>
			<Text fz="xs" c="dimmed" mt={3} mb="xl">
				Här ser du alla uppdrag som snart löper ut.
			</Text>
			{items}
		</Card>
	);
};
