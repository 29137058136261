import {
	add,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	isSameDay,
	isSameMonth,
	isSameWeek,
	isSameYear,
	startOfMonth,
	startOfWeek,
	sub,
} from "date-fns";
import { HeaderRow } from "./header-row";
import { useLayoutEffect, useState } from "react";
import { getProject } from "../../api/projects";
import { getMyAssignedProjects } from "../../api/me";
import { getRegisteredTimeInPeriodForUser } from "../../api/registered-times";
import { ProjectRow } from "./project-row";
import { betweenDates } from "../../utils/ledig/public-holiday";
import { Box, Button, Combobox, Container, Group, SegmentedControl, Text, useCombobox } from "@mantine/core";
import { IconCalendarPlus, IconChevronLeftPipe, IconChevronRightPipe } from "@tabler/icons-react";
import { useCurrentViewport } from "../../hooks/use-current-viewport";
import { useViewportSize } from "@mantine/hooks";
import { SummaryRow } from "./summary-row";
import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";
import type { ViewProject } from "./types";
import { getReportLocksForPeriod } from "../../api/report-locks";
import { LockButtons } from "./lock-buttons";

const MAX_TIMESTAMP = 8640000000000000;

export const Report = () => {
	const { smallScreen } = useCurrentViewport();
	const { width } = useViewportSize();

	const [weekendsLocked, setWeekendsLocked] = useState(true);
	const [dayRange, setDayRange] = useState<[Date, Date]>([startOfMonth(new Date()), endOfMonth(new Date())]);
	const [initialized, setInitialized] = useState(false); // Dirty hack. Sets to true when useViewportSize returns > 0.
	const [addedInternalProjects, setAddedInternalProjects] = useState<string[]>([]);
	const [search, setSearch] = useState("");
	const combobox = useCombobox({
		onDropdownClose: () => {
			combobox.resetSelectedOption();
			combobox.focusTarget();
			setSearch("");
		},

		onDropdownOpen: () => {
			combobox.focusSearchInput();
		},
	});

	const { data: registeredTimes } = useSuspenseQuery({
		queryKey: ["me", "registered_times", "range", dayRange[0].toString(), dayRange[1].toString()],
		queryFn: () => getRegisteredTimeInPeriodForUser(dayRange[0], dayRange[1]),
	});
	const { data: assignedProjects } = useSuspenseQuery({
		queryKey: ["me", "assigned_project", "range", dayRange[0].toString(), dayRange[1].toString()],
		queryFn: () => getMyAssignedProjects(dayRange[0], dayRange[1]),
	});
	const { data: lockedDates } = useSuspenseQuery({
		queryKey: ["me", "report_locks", dayRange[0].toString(), dayRange[1].toString()],
		queryFn: () => getReportLocksForPeriod(dayRange[0], dayRange[1]),
	});

	const projectResults = useSuspenseQueries({
		queries: assignedProjects.map(ap => ({
			queryKey: ["project", ap.project_id],
			queryFn: () => getProject(ap.project_id),
		})),
	});

	const publicHolidays = betweenDates(dayRange[0], dayRange[1]);
	const dateRange = eachDayOfInterval({ start: dayRange[0], end: dayRange[1] }).map(d => ({
		date: d,
		locked: !!lockedDates.find(ld => isSameDay(new Date(ld.date), d)),
		public_holiday: publicHolidays.find(ph => isSameDay(ph.date, d))?.name ?? null,
	}));

	const viewProjects: ViewProject[] = assignedProjects.map(ap => {
		const p = projectResults.find(p => p.data.id === ap.project_id);

		if (!p) throw new Error("Failed finding project for AssignedProject");
		const project = p.data;

		if (!project.customer) throw new Error("Missing customer on project");

		const timeRegisteredOnProject = registeredTimes.filter(rt => rt.project_id === project.id);

		return {
			assigned_project_id: ap.id,
			project_id: project.id,
			start_date: new Date(ap.start_date),
			end_date: ap.end_date === "infinity" ? new Date(MAX_TIMESTAMP) : new Date(ap.end_date),
			customer: project.customer,
			name: project.name,
			reported_times: timeRegisteredOnProject,
			visible:
				project.customer.name !== "Virki AB"
					? true
					: addedInternalProjects.includes(project.id) || timeRegisteredOnProject.length > 0,
		};
	});

	const setViewMode = (view: string) => {
		switch (view) {
			case "week": {
				const isOnCurrentMonth = isSameMonth(dayRange[0], new Date());
				if (isOnCurrentMonth)
					setDayRange([
						startOfWeek(new Date(), { weekStartsOn: 1 }),
						endOfWeek(new Date(), { weekStartsOn: 1 }),
					]);
				else
					setDayRange([
						startOfWeek(dayRange[0], { weekStartsOn: 1 }),
						endOfWeek(dayRange[0], { weekStartsOn: 1 }),
					]);
				return;
			}
			case "month": {
				const isOnCurrentWeek = isSameWeek(dayRange[0], new Date());
				if (isOnCurrentWeek) setDayRange([startOfMonth(new Date()), endOfMonth(new Date())]);
				else setDayRange([startOfMonth(dayRange[0]), endOfMonth(dayRange[0])]);
				return;
			}
		}
	};

	const handleSetPreviousPeriod = (view: string) => {
		switch (view) {
			case "week": {
				setDayRange([sub(dayRange[0], { weeks: 1 }), sub(dayRange[1], { weeks: 1 })]);
				return;
			}
			case "month": {
				setDayRange([
					startOfMonth(sub(dayRange[0], { months: 1 })),
					endOfMonth(sub(dayRange[0], { months: 1 })),
				]);
				return;
			}
		}
	};

	const handleSetNextPeriod = (view: string) => {
		switch (view) {
			case "week": {
				setDayRange([add(dayRange[0], { weeks: 1 }), add(dayRange[1], { weeks: 1 })]);
				return;
			}
			case "month": {
				setDayRange([
					startOfMonth(add(dayRange[0], { months: 1 })),
					endOfMonth(add(dayRange[0], { months: 1 })),
				]);
				return;
			}
		}
	};

	useLayoutEffect(() => {
		if (initialized) return;
		if (width > 0) {
			setViewMode(smallScreen ? "week" : "month");
			setInitialized(true);
		}
	}, [width]);

	const view = dateRange.length > 7 ? "month" : "week";

	const projectOptions = viewProjects
		.filter(vp => !vp.visible)
		.map(vp => (
			<Combobox.Option value={vp.project_id} key={vp.project_id}>
				{vp.name}
			</Combobox.Option>
		));

	return (
		<Container
			fluid
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "var(--mantine-spacing-xl)",
			}}
			p={smallScreen ? "md" : "xl"}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					gap: "var(--mantine-spacing-lg)",
				}}
			>
				<div>
					<SegmentedControl
						visibleFrom="md"
						value={view}
						onChange={setViewMode}
						data={[
							{
								value: "week",
								label: <Box>Vecka</Box>,
							},
							{
								value: "month",
								label: <Box>Månad</Box>,
							},
						]}
					/>
				</div>
				<div>
					<Button.Group>
						<Button variant="default" radius="md" onClick={() => handleSetPreviousPeriod(view)}>
							<IconChevronLeftPipe color="var(--mantine-color-teal-text)" />
						</Button>
						<Button.GroupSection
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "start",
								gap: "var(--mantine-spacing-sm)",
							}}
							variant="default"
							bg="var(--mantine-color-body)"
							miw={80}
						>
							<Text fz="sm" c="dimmed">
								{isSameYear(dayRange[0], dayRange[1])
									? format(dayRange[0], "yyyy")
									: `${format(dayRange[0], "yyyy")} - ${format(dayRange[1], "yyyy")}`}
							</Text>
							<Text fz="sm">
								{isSameMonth(dayRange[0], dayRange[1])
									? format(dayRange[0], "MMM")
									: `${format(dayRange[0], "MMM")} - ${format(dayRange[1], "MMM")}`}
							</Text>
							{isSameWeek(dayRange[0], dayRange[1]) && `Week ${format(dayRange[0], "w")}`}
						</Button.GroupSection>
						<Button variant="default" radius="md" onClick={() => handleSetNextPeriod(view)}>
							<IconChevronRightPipe color="var(--mantine-color-teal-text)" />
						</Button>
					</Button.Group>
				</div>
				<div />
			</div>
			<div
				style={{
					alignSelf: "center",
					gridTemplateColumns: `repeat(1, ${smallScreen ? "100px" : "150px"}) repeat(${dateRange.length}, minmax(auto, 40px)) ${!smallScreen ? "repeat(1, 60px) repeat(1, 80px) repeat(1, 1fr)" : ""}`,
					alignItems: "center",
					display: "grid",
					gap: "var(--mantine-spacing-xs) 0",
					width: "auto",
				}}
			>
				<HeaderRow dateRange={dateRange} />
				<div
					style={{
						gridColumn: "1 / -1",
						borderTop: "1px solid var(--mantine-color-default)",
					}}
				/>
				{viewProjects
					.filter(vp => vp.visible)
					.map(vp => (
						<ProjectRow
							weekendsLocked={weekendsLocked}
							dateRange={dateRange}
							key={vp.project_id}
							viewProject={vp}
						/>
					))}
				<div
					style={{
						gridColumn: "1 / -1",
						height: "var(--mantine-spacing-lg)",
					}}
				/>
				<SummaryRow dateRange={dateRange} viewProjects={viewProjects} />
			</div>
			<Group justify="space-between" pr="xl">
				<Combobox
					store={combobox}
					width={250}
					position="bottom-start"
					withArrow
					onOptionSubmit={val => {
						setAddedInternalProjects([...addedInternalProjects, val]);
						combobox.closeDropdown();
					}}
				>
					<Combobox.Target withAriaAttributes={false}>
						<Button onClick={() => combobox.toggleDropdown()}>Lägg till projekt</Button>
					</Combobox.Target>

					<Combobox.Dropdown>
						<Combobox.Search
							value={search}
							onChange={event => setSearch(event.currentTarget.value)}
							placeholder="Sök projekt"
						/>
						<Combobox.Options>
							{projectOptions.length > 0 ? (
								projectOptions
							) : (
								<Combobox.Empty>Inga tillgängliga projekt</Combobox.Empty>
							)}
						</Combobox.Options>
					</Combobox.Dropdown>
				</Combobox>
				<Group justify="end" pr="xl">
					<Button
						onClick={() => setWeekendsLocked(!weekendsLocked)}
						leftSection={<IconCalendarPlus size={14} />}
						variant="default"
					>
						{weekendsLocked ? "Unlock" : "Lock"} weekends
					</Button>

					<LockButtons dateRange={dateRange} dayRange={dayRange} view={view} />
				</Group>
			</Group>
		</Container>
	);
};
