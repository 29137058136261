import { createTheme, MantineProvider } from "@mantine/core";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { AuthProvider } from "./store/auth";

import "./global.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = document.getElementById("root");

const theme = createTheme({
	colors: {
		// Add your color
		vBlue: [
			"#e1f8ff",
			"#caecff",
			"#99d5ff",
			"#64befe",
			"#38aafc",
			"#1b9dfc",
			"#0097fd",
			"#0083e3",
			"#0074cc",
			"#0065b5",
		],
		vOrange: [
			"#fff1e1",
			"#ffe3cb",
			"#ffc59b",
			"#fea666",
			"#fc8b38",
			"#fc7a1b",
			"#fd710a",
			"#e15f00",
			"#c95300",
			"#af4500",
		],
	},
});

const queryClient = new QueryClient();

if (root) {
	createRoot(root).render(
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<MantineProvider defaultColorScheme="dark" theme={theme}>
					<AuthProvider>
						<RouterProvider router={router} />
					</AuthProvider>
				</MantineProvider>
			</QueryClientProvider>
		</StrictMode>,
	);
}
