import { Country } from "../countries";
import type { Country as CountryType } from "./types.ts";
import { boxingDay, christmasDay, christmasEpiphany, christmasEve } from "../days/christmas";
import {
	ascensionDay,
	dayOfPentecost,
	easterDay,
	easterEve,
	easterMonday,
	goodFriday,
	pentecost,
} from "../days/easter";
import { halloween } from "../days/halloween";
import { midsummerDay, midsummerEve } from "../days/midsummer";
import { newYearsDay, newYearsEve } from "../days/new-years";

export const holidays: CountryType = {
	name: "Sweden",
	code: Country.Sweden,
	days: [
		{
			name: "Julafton",
			producer: christmasEve,
		},
		{
			name: "Juldagen",
			producer: christmasDay,
		},
		{
			name: "Annandag jul",
			producer: boxingDay,
		},
		{
			name: "Påskdagen",
			producer: easterDay,
		},
		{
			name: "Kristi himmelfärdsdag",
			producer: ascensionDay,
		},
		{
			name: "Pingstdagen",
			producer: dayOfPentecost,
		},
		{
			name: "Långfredagen",
			producer: goodFriday,
		},
		{
			name: "Påskafton",
			producer: easterEve,
		},
		{
			name: "Annandag påsk",
			producer: easterMonday,
		},
		{
			name: "Pingstafton",
			producer: pentecost,
		},
		{
			name: "Alla helgons dag",
			producer: halloween,
		},
		{
			name: "Första maj",
			producer: (year: number) => {
				const dateObj = new Date(`${year}`);

				dateObj.setMonth(4);
				dateObj.setDate(1);

				return dateObj;
			},
		},
		{
			name: "Midsommardagen",
			producer: midsummerDay,
		},
		{
			name: "Midsommarafton",
			producer: midsummerEve,
		},
		{
			name: "Nationaldagen",
			producer: (year: number) => {
				const dateObj = new Date(`${year}`);

				dateObj.setMonth(5);
				dateObj.setDate(6);

				return dateObj;
			},
		},
		{
			name: "Nyårsafton",
			producer: newYearsEve,
		},
		{
			name: "Nyårsdagen",
			producer: newYearsDay,
		},
		{
			name: "Trettondag jul",
			producer: christmasEpiphany,
		},
	],
};
