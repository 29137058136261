import { Flex, NumberInput, Stack, TextInput } from "@mantine/core";
import { useTimeReportStore } from "./store";

import { format, isSameDay } from "date-fns";

import classes from "./project-row.module.css";

import Duration from "@icholy/duration";
import type { DateRange } from "./helpers";

type Props = {
	dates: DateRange[];
};

export const SummaryRow = ({ dates }: Props) => {
	const { reportedTime } = useTimeReportStore();

	const totalTime = reportedTime.reduce((acc, curr) => acc + new Duration(curr.time).hours(), 0);

	return (
		<Stack gap={8} py="md">
			<Flex className={classes.projectRow}>
				{dates.map(({ date }) => {
					const value = reportedTime
						.filter(r => isSameDay(r.date, date))
						.reduce((acc, curr) => acc + new Duration(curr.time).hours(), 0);

					return (
						<TextInput
							key={date.getTime()}
							value={value}
							classNames={{ input: classes.dayTotal }}
							className={classes.date}
							disabled
							wrapperProps={{
								"data-date": format(date, "MMM d"),
							}}
						/>
					);
				})}
				<div className={classes.total}>
					<NumberInput
						value={totalTime}
						className={classes.date}
						wrapperProps={{
							"data-date": "Total",
						}}
						disabled
						hideControls
						classNames={{ input: classes.totalInput }}
					/>
				</div>
			</Flex>
		</Stack>
	);
};
