import { format } from "date-fns";
import type { OutputLockReminder, OutputReportLock } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const createReportLocks = (dates: string[]) => {
	return timeFetch<Array<OutputReportLock>>(`${API_BASE_URL}/api/v1/report_locks`, {
		method: "POST",
		body: JSON.stringify(dates),
	});
};

export const deleteReportLocks = (userId: string, dates: string[]) => {
	return timeFetch<boolean>(`${API_BASE_URL}/api/v1/users/${userId}/report_locks`, {
		method: "DELETE",
		body: JSON.stringify(dates),
	});
};

export const getReportLocksForPeriod = (startDate: Date, endDate: Date) => {
	return timeFetch<Array<OutputReportLock>>(
		`${API_BASE_URL}/api/v1/report_locks?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET" },
	);
};

export const getReportLocksForUserInPeriod = (userId: string, startDate: Date, endDate: Date) => {
	return timeFetch<Array<OutputReportLock>>(
		`${API_BASE_URL}/api/v1/report_locks/user/${userId}?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET" },
	);
};

export const getReportLockReminders = (from: Date, to: Date) => {
	return timeFetch<Array<OutputLockReminder>>(
		`${API_BASE_URL}/api/v1/report_locks/reminders?from=${format(from, "yyyy-MM-dd")}&to=${format(to, "yyyy-MM-dd")}`,
		{ method: "GET" },
	);
};
