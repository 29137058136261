import {
	Box,
	Button,
	Card,
	Container,
	PasswordInput,
	Space,
	Tabs,
	Text,
	getGradient,
	useMantineTheme,
} from "@mantine/core";
import { hasLength, matchesField, useForm } from "@mantine/form";
import { IconInfoCircle, IconKey, IconSettings } from "@tabler/icons-react";
import { useState } from "react";
import { updatePassword } from "../../api/me";

export const UserSettings = () => {
	const theme = useMantineTheme();
	const [upadingPassword, setUpdatingPassword] = useState<boolean>(false);
	const [error, setError] = useState<string>();

	const changePasswordForm = useForm({
		initialValues: {
			oldPassword: "",
			newPassword: "",
			repeatedNewPassword: "",
		},
		validate: {
			newPassword: hasLength({ min: 12 }, "Password must be at lest 12 charcters long"),
			repeatedNewPassword: matchesField("newPassword", "Passwords do not match"),
		},
		validateInputOnChange: ["repeatedNewPassword", "newPassword"],
	});

	const handleUpdatePassword = () => {
		setUpdatingPassword(true);
		updatePassword({
			oldPassword: changePasswordForm.values.oldPassword,
			newPassword: changePasswordForm.values.newPassword,
		})
			.catch(err => setError(err.message))
			.finally(() => setUpdatingPassword(false));
	};

	return (
		<Container>
			<Space h="md" />
			<Card shadow="sm" padding="lg" radius="md" withBorder>
				<Card.Section>
					<Box
						style={{
							backgroundImage: getGradient({ from: "orange", to: "gray", deg: 45 }, theme),
							color: theme.white,
						}}
					>
						<IconSettings width={128} height={128} />
					</Box>
				</Card.Section>

				<Space h="md" />
				<Tabs defaultValue="user_info" orientation="vertical">
					<Tabs.List>
						<Tabs.Tab leftSection={<IconInfoCircle size="1rem" />} value="user_info">
							User Info
						</Tabs.Tab>
						<Tabs.Tab leftSection={<IconKey size="1rem" />} value="change_password">
							Change Password
						</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value="user_info">
						<Container>User Info</Container>
					</Tabs.Panel>
					<Tabs.Panel value="change_password">
						<Container>
							<PasswordInput label="Old password" {...changePasswordForm.getInputProps("oldPassword")} />
							<PasswordInput label="New password" {...changePasswordForm.getInputProps("newPassword")} />
							<PasswordInput
								label="Repeat new password"
								{...changePasswordForm.getInputProps("repeatedNewPassword")}
							/>
							<Button
								loading={upadingPassword}
								onClick={handleUpdatePassword}
								disabled={!changePasswordForm.isValid()}
								type="submit"
								mt="xl"
							>
								Save
							</Button>
							<Text c="red">{error}</Text>
						</Container>
					</Tabs.Panel>
				</Tabs>
			</Card>
		</Container>
	);
};
