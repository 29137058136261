import type { NationalHolidayProducerFunc } from "../public-holiday";

export const easterDay: NationalHolidayProducerFunc = Y => {
	const C = Math.floor(Y / 100);
	const N = Y - 19 * Math.floor(Y / 19);
	const K = Math.floor((C - 17) / 25);
	let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
	I = I - 30 * Math.floor(I / 30);
	I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
	let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
	J = J - 7 * Math.floor(J / 7);
	const L = I - J;
	const M = 3 + Math.floor((L + 40) / 44);
	const D = L + 28 - 31 * Math.floor(M / 4);

	return new Date(Y, M - 1, D);
};

export const ascensionDay: NationalHolidayProducerFunc = (year: number) => {
	const easterDate = new Date(easterDay(year).getTime());

	// 6 weeks after easter
	easterDate.setDate(easterDate.getDate() + 6 * 7);
	// Thursday
	easterDate.setDate(easterDate.getDate() - 3);

	return easterDate;
};

export const dayOfPentecost: NationalHolidayProducerFunc = year => {
	const easterDate = new Date(easterDay(year).getTime());

	easterDate.setDate(easterDate.getDate() + 7 * 7);

	return easterDate;
};

export const goodFriday: NationalHolidayProducerFunc = year => {
	const easterDate = new Date(easterDay(year).getTime());

	easterDate.setDate(easterDate.getDate() - 2);

	return easterDate;
};

export const easterEve: NationalHolidayProducerFunc = year => {
	const easterDate = new Date(easterDay(year).getTime());

	easterDate.setDate(easterDate.getDate() - 1);

	return easterDate;
};

export const easterMonday: NationalHolidayProducerFunc = year => {
	const easterDate = new Date(easterDay(year).getTime());

	easterDate.setDate(easterDate.getDate() + 1);

	return easterDate;
};

export const pentecost: NationalHolidayProducerFunc = year => {
	const easterDate = new Date(dayOfPentecost(year).getTime());

	easterDate.setDate(easterDate.getDate() - 1);

	return easterDate;
};
