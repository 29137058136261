import { useEffect, useState } from "react";
import { getCustomers } from "../../api/customers";
import { Button, Container, Flex, Table } from "@mantine/core";
import type { Customer } from "../../generated";
import { format } from "date-fns";
import { Link } from "react-router-dom";

export const Customers = () => {
	const [customers, setCustomers] = useState<Customer[]>([]);

	useEffect(() => {
		getCustomers().then(setCustomers);
	}, []);

	return (
		<Container>
			<Flex justify="end">
				<Button component={Link} to={"/customersv2/create"}>
					New
				</Button>
			</Flex>
			<Table>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>Name</Table.Th>
						<Table.Th>Website</Table.Th>
						<Table.Th>Customer since</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{customers.map(customer => (
						<Table.Tr key={customer.id}>
							<Table.Td>
								<Link to={`/customersv2/${customer.id}`}>{customer.name}</Link>
							</Table.Td>
							<Table.Td>
								<Link to={customer.domain}>{customer.domain}</Link>
							</Table.Td>
							<Table.Td>{format(customer.created_at, "yyyy-MM-dd")}</Table.Td>
						</Table.Tr>
					))}
				</Table.Tbody>
			</Table>
		</Container>
	);
};
