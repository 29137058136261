import { Fragment, useEffect, useState } from "react";
import type { OutputReportLock } from "../../generated";
import { add as addNotification } from "../../store/notifications";
import {
	add,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	isSameDay,
	isSameMonth,
	startOfMonth,
	startOfWeek,
	sub,
} from "date-fns";
import { deleteReportLocks, getReportLocksForUserInPeriod } from "../../api/report-locks";
import classes from "./month-report.module.css";
import clsx from "clsx";
import { Button, Center, Grid, Modal, Text } from "@mantine/core";
import { IconArrowLeft, IconArrowRight, IconLock } from "@tabler/icons-react";
import { betweenDates } from "../../utils/ledig/public-holiday";

const UnlockWeekButton = ({ onUnlock }: { onUnlock: () => void }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Button onClick={onUnlock} leftSection={<IconArrowLeft />}>
				Unlock
			</Button>
		</div>
	);
};

type IMonthReport = {
	userId: string;
};

export const MonthReport = ({ userId }: IMonthReport) => {
	const [reportLocks, setReportLocks] = useState<Array<OutputReportLock>>([]);
	const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
	const [confirmModal, setConfirmModal] = useState<Date[]>([]);

	useEffect(() => {
		if (!userId) return;

		const som = startOfWeek(startOfMonth(selectedMonth), {
			weekStartsOn: 1,
		});
		const eom = endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 });

		getReportLocksForUserInPeriod(userId, som, eom).then(setReportLocks);
	}, [userId, selectedMonth]);

	const datesInRange = eachDayOfInterval({
		start: startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 }),
		end: endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 }),
	});

	const publicHolidays = betweenDates(
		startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 }),
		endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 }),
	);

	const handleUnlockDates = (dates: Date[]) => {
		deleteReportLocks(
			userId,
			dates.filter(d => isSameMonth(d, selectedMonth)).map(d => format(d, "yyyy-MM-dd")),
		).then(() => {
			getReportLocksForUserInPeriod(
				userId,
				startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 }),
				endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 }),
			)
				.then(setReportLocks)
				.finally(() => {
					const daysStr =
						dates.length > 1
							? `${format(dates[0], "MMM dd")} to ${format(dates[dates.length - 1], "MMM dd")}`
							: format(dates[0], "MMM dd");

					addNotification({
						title: "Locks removed",
						message: `Removed lock from ${daysStr}`,
						lifetime: 3000,
					});

					setConfirmModal([]);
				});
		});
	};

	const handleRemoveClick = (dates: Date[]) => {
		setConfirmModal(dates);
	};

	return (
		<div>
			<Grid>
				<Grid.Col span={4} offset={4}>
					<Center>
						<IconArrowLeft onClick={() => setSelectedMonth(sub(selectedMonth, { months: 1 }))} />
						<h2>{format(selectedMonth, "MMM yyyy")}</h2>
						<IconArrowRight onClick={() => setSelectedMonth(add(selectedMonth, { months: 1 }))} />
					</Center>
				</Grid.Col>
				<Grid.Col span={2} offset={2}>
					<Button onClick={() => setSelectedMonth(new Date())}>Current month</Button>
				</Grid.Col>
			</Grid>
			<div className={classes.monthGrid}>
				{datesInRange.map((d, i) => {
					const dayIsLocked = reportLocks.find(rl => isSameDay(d, rl.date));
					const isPublicHoliday = publicHolidays.map(ph => ph.date).find(ph => isSameDay(ph, d));

					return (
						<Fragment key={format(d, "yyyy-MM-dd")}>
							<div
								className={clsx(
									classes.day,
									isPublicHoliday && classes.publicHoliday,
									!isSameMonth(d, selectedMonth) && classes.isOtherMonth,
									isSameDay(d, new Date()) && classes.isToday,
								)}
								onClick={() => handleRemoveClick([d])}
							>
								<span>{format(d, "d")}</span>
								{dayIsLocked && (
									<div className={classes.locked}>
										<IconLock size={60} opacity={0.3} />
									</div>
								)}
							</div>
							{i % 7 === 6 && (
								<UnlockWeekButton
									onUnlock={() => handleRemoveClick([...datesInRange].splice(i - 6, 7))}
								/>
							)}
						</Fragment>
					);
				})}
			</div>

			<Modal opened={confirmModal.length > 0} onClose={() => {}} title="Är du säker att du vill ta bort låsen?">
				{confirmModal.length > 0 && (
					<div>
						{confirmModal.length > 1 ? (
							<Text>
								Är du säker att du vill ta bort låsen mellan {format(confirmModal[0], "yyyy-MM-dd")} och{" "}
								{format(confirmModal[confirmModal.length - 1], "yyyy-MM-dd")}?
							</Text>
						) : (
							<Text>
								Är du säker att du vill ta bort låset för {format(confirmModal[0], "yyyy-MM-dd")}?
							</Text>
						)}
					</div>
				)}
				<div
					style={{
						marginTop: "15px",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Button
						onClick={() => {
							setConfirmModal([]);
						}}
					>
						Avbryt
					</Button>
					<Button onClick={() => handleUnlockDates(confirmModal)} color="red">
						Ta bort
					</Button>
				</div>
			</Modal>
		</div>
	);
};
