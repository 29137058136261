import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import type { User } from "../../generated";

import { ActionIcon, Anchor, Button, Container, Grid, Popover, ScrollArea, Switch, Table } from "@mantine/core";
import { IconCirclePlus, IconTrashX } from "@tabler/icons-react";
import { deleteUser, getUsers, userTypeToReadable } from "../../api/users";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { ReportLineOverview } from "../../components/report-line-overview";

const to = endOfMonth(new Date());
const from = startOfMonth(sub(to, { months: 1 }));

export const Users = () => {
	const [showInactive, setShowInactive] = useState<boolean>(false);
	const [users, setUsers] = useState<Array<User>>([]);

	useEffect(() => {
		getUsers().then(setUsers);
	}, []);

	const handleDeleteUser = (objId: string) => {
		deleteUser(objId).then(() => {
			setUsers(
				users.filter(val => {
					return objId !== val.id;
				}),
			);
		});
	};

	const rows = users
		.filter(u => (showInactive ? true : u.active))
		.map(user => {
			return (
				<Table.Tr key={user.id}>
					<Table.Td>
						<Anchor component={Link} to={`/users/${user.id}`}>
							{user.name}
						</Anchor>
					</Table.Td>
					<Table.Td>
						<ReportLineOverview from={from} to={to} user={user} />
					</Table.Td>
					<Table.Td>{user.email}</Table.Td>
					<Table.Td>{user.active ? "Ja" : "Nej"}</Table.Td>
					<Table.Td>{user.admin ? "Ja" : "Nej"}</Table.Td>
					<Table.Td>{userTypeToReadable(user.user_type)}</Table.Td>
					<Table.Td>
						<Popover position="bottom" withArrow shadow="md">
							<Popover.Target>
								<ActionIcon color="red" variant="filled">
									<IconTrashX size={18} />
								</ActionIcon>
							</Popover.Target>
							<Popover.Dropdown>
								<Button
									onClick={() => {
										handleDeleteUser(user.id);
									}}
								>
									Confirm delete
								</Button>
							</Popover.Dropdown>
						</Popover>
					</Table.Td>
				</Table.Tr>
			);
		});

	return (
		<Container fluid p="xl">
			<Grid>
				<Grid.Col span={2}>
					<h1>Users</h1>
					<Switch
						checked={showInactive}
						onChange={() => setShowInactive(!showInactive)}
						label="Show inactive users"
					/>
				</Grid.Col>
				<Grid.Col span={2} offset={8}>
					<Button component={Link} to={"/users/new"} leftSection={<IconCirclePlus />}>
						New User
					</Button>
				</Grid.Col>

				<Grid.Col span={12}>
					<ScrollArea>
						<Table style={{ minWidth: 800 }} verticalSpacing="xs">
							<Table.Thead>
								<Table.Tr>
									<Table.Th>Name</Table.Th>
									<Table.Th>Report Status</Table.Th>
									<Table.Th>Email</Table.Th>
									<Table.Th>Activ</Table.Th>
									<Table.Th>Admin</Table.Th>
									<Table.Th>Type</Table.Th>
									<Table.Th />
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>{rows}</Table.Tbody>
						</Table>
					</ScrollArea>
				</Grid.Col>
			</Grid>
		</Container>
	);
};
