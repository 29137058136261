import { useEffect, useState } from "react";
import { getReportLockReminders } from "../../../api/report-locks";
import { addMonths, endOfMonth, format, startOfMonth } from "date-fns";
import type { OutputLockReminder, User } from "../../../generated";
import { Card, Group, Text } from "@mantine/core";
import classes from "./lock-reminder.module.css";
import { getUser } from "../../../api/users";

function groupContinuousDateRanges(dates: string[]): [string, string][] {
	// Parse and sort the dates
	const parsedDates: Date[] = dates.map(date => new Date(date)).sort((a, b) => a.getTime() - b.getTime());

	const result: [string, string][] = [];
	let rangeStart: Date = parsedDates[0];

	for (let i = 1; i < parsedDates.length; i++) {
		const current: Date = parsedDates[i];
		const previous: Date = parsedDates[i - 1];

		// Check if the current date is not continuous with the previous date
		if ((current.getTime() - previous.getTime()) / (1000 * 60 * 60 * 24) > 1) {
			// Push the range and start a new range
			result.push([rangeStart.toISOString(), previous.toISOString()]);
			rangeStart = current;
		}
	}

	// Add the last range
	result.push([rangeStart.toISOString(), parsedDates[parsedDates.length - 1].toISOString()]);

	return result;
}

export const ReportLockReminder = () => {
	const startDate = addMonths(startOfMonth(new Date()), -2);
	const endDate = addMonths(endOfMonth(new Date()), -1);

	const [users, setUsers] = useState<User[]>([]);
	const [lockReminders, setLockreminders] = useState<OutputLockReminder[]>([]);
	useEffect(() => {
		getReportLockReminders(startDate, endDate).then(setLockreminders);
	}, []);

	useEffect(() => {
		const missingUserIds = lockReminders.filter(lr => !users.some(u => u.id === lr.user_id)).map(lr => lr.user_id);

		Promise.all(missingUserIds.map(u => getUser(u))).then(newUsers => setUsers([...users, ...newUsers]));
	}, [lockReminders]);

	return (
		<Card withBorder radius="md" p="xl" className={classes.card}>
			<Text fz="lg" className={classes.title} fw={500}>
				Olåsta tider
			</Text>
			<Text fz="xs" c="dimmed" mt={3} mb="xl">
				Här är alla personer som inte låst saker senaste tiden.
			</Text>
			{lockReminders.map(lr => {
				const user = users.find(u => u.id === lr.user_id);
				const dateRanges = groupContinuousDateRanges(lr.dates);
				return (
					<Group justify="space-between" className={classes.item} wrap="nowrap" gap="xl" key={lr.user_id}>
						<Text>{user?.name}</Text>
						<div>
							{dateRanges.map(range => (
								<Text key={`${range[0]}-${range[1]}}`} size="xs" c="dimmed">
									{format(range[0], "yyyy-MM-dd")} - {format(range[1], "yyyy-MM-dd")}
								</Text>
							))}
						</div>
					</Group>
				);
			})}
		</Card>
	);
};
