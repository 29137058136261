import { Badge, Button, Flex, Title } from "@mantine/core";
import type { InputRegisteredTime, Project } from "../../../generated";
import { format, isWeekend } from "date-fns";
import { type DateRange, isLocked } from "../helpers";
import { batchRegisterTime, getRegisteredTimeInPeriodForUser } from "../../../api/registered-times";
import { setReportTimes, useTimeReportStore } from "../store";

type Props = {
	project: Project;
	dates: DateRange[];
};

export const ProjectRowHeader = ({ project, dates }: Props) => {
	const startDate = useTimeReportStore(store => store.startDate);
	const endDate = useTimeReportStore(store => store.endDate);
	const weekendsLocked = useTimeReportStore(store => store.weekendsLocked);

	const handleFullTime = () => {
		const fullTime: InputRegisteredTime[] = [];
		for (const d of dates) {
			if ((weekendsLocked && (isWeekend(d.date) || d.dayOff)) || isLocked(d.date)) {
				continue;
			}

			fullTime.push({
				project: project.id,
				date: format(d.date, "yyyy-MM-dd"),
				time: "8h",
			});
		}
		batchRegisterTime(fullTime).then(() =>
			getRegisteredTimeInPeriodForUser(startDate, endDate).then(setReportTimes),
		);
	};

	return (
		<Flex align="center" mb="md" justify="space-between">
			<Flex gap={8} align="center">
				<Title order={6}>{project.name}</Title>
				<Badge color="orange" variant="light" size="xs">
					{project.customer?.name}
				</Badge>
				<Button size="compact-xs" onClick={handleFullTime}>
					Full time
				</Button>
			</Flex>
		</Flex>
	);
};
