import { addMonths, addWeeks, startOfMonth, startOfWeek, subMonths, subWeeks } from "date-fns";
import { createStore } from "zustand/vanilla";
import { useStore } from "zustand";
import type { OutputRegisteredTime, OutputReportLock, Project } from "../../../generated";
import { getSelectedDates } from "../helpers";
import { getMyAssignedProjects } from "../../../api/me";
import { getProjectsFromAssignedProjects } from "../../../api/projects";
import { getReportLocksForPeriod } from "../../../api/report-locks";
import { getRegisteredTimeInPeriodForUser } from "../../../api/registered-times";

export type View = "month" | "week";

type ProjectInfo = {
	id: string;
	startDate: string;
	endDate: string;
};

type TimeReportState = {
	view: View;
	selectedDate: Date;
	reportedTime: OutputRegisteredTime[];
	reportLocks: OutputReportLock[];
	projectInfo: ProjectInfo[];
	projects: Project[];
	startDate: Date;
	endDate: Date;
	weekendsLocked: boolean;
};

export const timeReportStore = createStore<TimeReportState>()(() => {
	const { startDate, endDate } = getSelectedDates(new Date(), "month");
	return {
		view: "month",
		selectedDate: new Date(),
		reportedTime: [],
		reportLocks: [],
		projectInfo: [],
		projects: [],
		startDate,
		endDate,
		weekendsLocked: true,
	};
});

export const fetchReportData = async (startDate: Date, endDate: Date) => {
	await Promise.all([
		getMyAssignedProjects(startDate, endDate).then(assignedProjects => {
			const projectInfo = assignedProjects.map(ap => ({
				id: ap.project_id,
				startDate: ap.start_date,
				endDate: ap.end_date,
			}));
			setProjectInfo(projectInfo);
			getProjectsFromAssignedProjects(assignedProjects).then(res =>
				setProjects(res.sort((a, b) => a.name.localeCompare(b.name))),
			);
		}),
		getRegisteredTimeInPeriodForUser(startDate, endDate).then(setReportTimes),
		getReportLocksForPeriod(startDate, endDate).then(setReportLocks),
	]);
};

export const setView = () =>
	timeReportStore.setState(({ view }) => ({
		view: view === "month" ? "week" : "month",
	}));

export const initializeState = (view: View, selectedDate: Date) => {
	const { startDate, endDate } = getSelectedDates(selectedDate, view);

	timeReportStore.setState(() => ({
		view: view,
		selectedDate,
		startDate,
		endDate,
	}));
};

export const incrementDate = () => {
	const { view, selectedDate } = timeReportStore.getState();
	const newDate =
		view === "month"
			? addMonths(startOfMonth(selectedDate), 1)
			: addWeeks(startOfWeek(selectedDate, { weekStartsOn: 1 }), 1);
	const { startDate, endDate } = getSelectedDates(newDate, view);

	fetchReportData(startDate, endDate).then(() => {
		timeReportStore.setState(() => {
			return {
				startDate,
				endDate,
				selectedDate: newDate,
			};
		});
	});
};

export const decrementDate = () => {
	const { view, selectedDate } = timeReportStore.getState();
	const newDate =
		view === "month"
			? subMonths(startOfMonth(selectedDate), 1)
			: subWeeks(startOfWeek(selectedDate, { weekStartsOn: 1 }), 1);
	const { startDate, endDate } = getSelectedDates(newDate, view);

	fetchReportData(startDate, endDate).then(() => {
		timeReportStore.setState(() => {
			return {
				startDate,
				endDate,
				selectedDate: newDate,
			};
		});
	});
};

export const setReportTimes = (reportedTime: OutputRegisteredTime[]) => {
	timeReportStore.setState(() => ({
		reportedTime,
	}));
};

export const setReportedTime = (reportedTime: OutputRegisteredTime) => {
	timeReportStore.setState(state => {
		const rts = [...state.reportedTime];
		const existingIdx = state.reportedTime.findIndex(
			rt =>
				reportedTime.date === rt.date &&
				reportedTime.project_id === rt.project_id &&
				reportedTime.user_id === rt.user_id,
		);

		if (existingIdx > -1) {
			rts[existingIdx] = reportedTime;
			return {
				reportedTime: rts,
			};
		}

		return {
			reportedTime: [...rts, reportedTime],
		};
	});
};

export const setProjects = (projects: Project[]) => {
	timeReportStore.setState(() => ({
		projects,
	}));
};

export const setReportLocks = (reportLocks: OutputReportLock[]) => {
	timeReportStore.setState(() => ({
		reportLocks,
	}));
};

export const setProjectInfo = (projectInfo: ProjectInfo[]) => {
	timeReportStore.setState(() => ({
		projectInfo,
	}));
};

export const toggleWeekendsLocked = () => {
	timeReportStore.setState(state => ({
		weekendsLocked: !state.weekendsLocked,
	}));
};

export function useTimeReportStore(): TimeReportState;
export function useTimeReportStore<T>(selector: (state: TimeReportState) => T): T;
export function useTimeReportStore<T>(selector?: (state: TimeReportState) => T) {
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	return useStore(timeReportStore, selector!);
}
