import type { FallbackProps } from "react-error-boundary";
import { add as addNotification } from "./store/notifications";

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
	if (error) {
		addNotification({
			title: "Generic error",
			message: error.message,
		});
	}

	return [];
};
