import { format, isSameDay, isWeekend, isWithinInterval, sub } from "date-fns";
import { Button, Input, Text } from "@mantine/core";
import type { ViewDate, ViewProject } from "./types";
import Duration from "@icholy/duration";
import { batchRegisterTime, registerTime } from "../../api/registered-times";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCurrentViewport } from "../../hooks/use-current-viewport";

type IProjectRow = {
	dateRange: ViewDate[];
	viewProject: ViewProject;
	weekendsLocked: boolean;
};

export const ProjectRow = ({ dateRange, viewProject, weekendsLocked }: IProjectRow) => {
	const { smallScreen } = useCurrentViewport();

	const queryClient = useQueryClient();

	const { mutate: handleSetTime } = useMutation({
		mutationKey: ["me", "registered_times"],
		mutationFn: (vars: { date: Date; projectId: string; time: string }) => {
			const { date, projectId, time } = vars;

			return registerTime({
				date: format(date, "yyyy-MM-dd"),
				project: projectId,
				time: `${time === "" ? 0 : time}h`,
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["me", "registered_times"] });
		},
	});

	const handleSetFullTime = (projectId: string) => {
		const reports = dateRange
			.filter(date => !date.public_holiday && !date.locked && !isWeekend(date.date))
			.map(d => ({
				date: format(d.date, "yyyy-MM-dd"),
				project: projectId,
				time: "8h",
			}));
		batchRegisterTime(reports).finally(() =>
			queryClient.invalidateQueries({ queryKey: ["me", "registered_times"] }),
		);
	};

	return (
		<>
			<div style={{ paddingRight: "var(--mantine-spacing-lg)" }}>
				<Text fz="sm">{viewProject.name}</Text>
				<Text fz="xs" c="dimmed">
					{viewProject.customer.name}
				</Text>
			</div>
			{dateRange.map(viewDate => {
				const reportedTime = viewProject.reported_times.find(rt => isSameDay(new Date(rt.date), viewDate.date));
				const projectAvailable = isWithinInterval(viewDate.date, {
					start: sub(viewProject.start_date, { days: 1 }),
					end: viewProject.end_date,
				});

				const duration = new Duration(reportedTime?.time);

				return (
					<div
						style={{
							height: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center",
						}}
						key={format(viewDate.date, "yyyy-MM-dd")}
					>
						<Input
							onClick={ev => {
								ev.currentTarget.focus();
								ev.currentTarget.select();
							}}
							type="tel"
							onChange={ev =>
								handleSetTime({
									date: viewDate.date,
									projectId: viewProject.project_id,
									time: ev.target.value,
								})
							}
							disabled={
								viewDate.locked ||
								!projectAvailable ||
								(weekendsLocked && (isWeekend(viewDate.date) || !!viewDate.public_holiday))
							}
							styles={{
								input: {
									textAlign: "center",
									paddingInlineStart: "calc(var(--input-padding-inline-start) / 2)",
									paddingInlineEnd: "calc(var(--input-padding-inline-start) / 2)",
									width: "2.2em",
								},
							}}
							placeholder="0"
							defaultValue={reportedTime ? duration.hours() : undefined}
						/>
					</div>
				);
			})}
			{!smallScreen && (
				<>
					<div
						style={{ paddingLeft: "var(--mantine-spacing-sm)", paddingRight: "var(--mantine-spacing-sm)" }}
					>
						{viewProject.reported_times.reduce((acc, curr) => {
							const duration = new Duration(curr.time);

							return duration.hours() + acc;
						}, 0)}
					</div>
					<Button onClick={() => handleSetFullTime(viewProject.project_id)}>Heltid</Button>
					<div />
				</>
			)}
		</>
	);
};
