import { Button, Group } from "@mantine/core";
import type { ViewDate } from "./types";
import { IconLock } from "@tabler/icons-react";
import { createReportLocks } from "../../api/report-locks";
import { format, isSameMonth } from "date-fns";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type ILockButtons = {
	dayRange: [Date, Date];
	view: "week" | "month";
	dateRange: ViewDate[];
};

export const LockButtons = ({ dayRange, view, dateRange }: ILockButtons) => {
	const queryClient = useQueryClient();

	const { mutate: lockPeriod } = useMutation({
		mutationKey: ["me", "report_locks"],
		mutationFn: () => {
			return createReportLocks(dateRange.filter(d => !d.locked).map(d => format(d.date, "yyyy-MM-dd")));
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["me", "report_locks"] });
		},
	});

	const { mutate: lockPeriodInMonth } = useMutation({
		mutationKey: ["me", "report_locks"],
		mutationFn: (dateInMonth: Date) => {
			return createReportLocks(
				dateRange
					.filter(d => !d.locked)
					.filter(d => isSameMonth(dateInMonth, d.date))
					.map(d => format(d.date, "yyyy-MM-dd")),
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["me", "report_locks"] });
		},
	});

	const isSplitMonth = !isSameMonth(dayRange[0], dayRange[1]);

	if (isSplitMonth)
		return (
			<Group>
				<Button
					onClick={() => lockPeriodInMonth(dayRange[0])}
					variant="light"
					leftSection={<IconLock size={14} />}
				>
					Lock {view} in {format(dayRange[0], "MMM")}
				</Button>
				<Button
					onClick={() => lockPeriodInMonth(dayRange[1])}
					variant="light"
					leftSection={<IconLock size={14} />}
				>
					Lock {view} in {format(dayRange[1], "MMM")}
				</Button>
			</Group>
		);

	return (
		<Group>
			<Button onClick={() => lockPeriod()} variant="light" leftSection={<IconLock size={14} />}>
				Lock {view}
			</Button>
		</Group>
	);
};
