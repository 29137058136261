import { Code, Group, ScrollArea } from "@mantine/core";
import { IconBuildingCommunity, IconClock, IconGauge, IconUsers } from "@tabler/icons-react";
import { useAuth } from "../store/auth";
import classes from "./left-menu.module.css";
import { LinksGroup } from "./links-group";
import { Logo } from "./logo";
import { UserButton } from "./user-button";

const getMenu = (admin: boolean) => {
	if (admin) {
		return [
			{ label: "Dashboard", icon: IconGauge, link: "/" },
			{
				label: "Rapportera tid",
				icon: IconClock,
				link: "/report",
			},
			{
				label: "Rapportera tid (next)",
				icon: IconClock,
				link: "/report-next",
			},
			{
				label: "Kunder",
				icon: IconBuildingCommunity,
				link: "/customersv2",
			},
			{ label: "Användare", icon: IconUsers, link: "/users" },
			{ label: "Rapport", icon: IconUsers, link: "/summary" },
			{ label: "Utils", icon: IconUsers, link: "/utils" },
			/*  {
			label: 'Security',
			icon: IconLock,
			links: [
			  { label: 'Enable 2FA', link: '/' },
			  { label: 'Change password', link: '/' },
			  { label: 'Recovery codes', link: '/' },
			],
		  },*/
		];
	}

	return [
		{ label: "Dashboard", icon: IconGauge, link: "/" },
		{ label: "Rapportera tid", icon: IconClock, link: "/report" },
		{
			label: "Rapportera tid (next)",
			icon: IconClock,
			link: "/report-next",
		},
	];
};

export function LeftMenu() {
	const { state } = useAuth();

	const links = getMenu(state.user ? state.user?.Groups.includes("admin") : false).map(item => (
		<LinksGroup key={item.label} {...item} />
	));

	return (
		<nav className={classes.navbar}>
			<div className={classes.header}>
				<Group justify="space-between">
					<Logo width={60} />
					<Code fw={700}>v3.1.21</Code>
				</Group>
			</div>

			<ScrollArea className={classes.links}>
				<div className={classes.linksInner}>{links}</div>
			</ScrollArea>

			<div className={classes.footer}>
				{state?.isAuthenticated && <UserButton name={state?.user?.Name || ""} />}
			</div>
		</nav>
	);
}
