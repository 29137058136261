import { format, getWeek } from "date-fns";
import { decrementDate, incrementDate, useTimeReportStore } from "./store";
import { ActionIcon } from "@mantine/core";
import { IconCaretLeft, IconCaretRight } from "@tabler/icons-react";

import classes from "./date-switcher.module.css";

export const DateSwitcher = () => {
	const { selectedDate, view } = useTimeReportStore();
	const weekView = view === "week";

	return (
		<div className={classes.dateSwitcher}>
			<ActionIcon size="lg" onClick={decrementDate}>
				<IconCaretLeft />
			</ActionIcon>
			<div className={classes.period}>
				<div className={classes.month}>{format(selectedDate, "LLLL yyy")}</div>
				{weekView && <div className={classes.week}>Week {getWeek(selectedDate)}</div>}
			</div>
			<ActionIcon size="lg" onClick={incrementDate}>
				<IconCaretRight />
			</ActionIcon>
		</div>
	);
};
