import { holidays as sweden } from "./countries/sv_SE";

export type NationalHolidayProducerFunc = (year: number) => Date;

export const getDays = (year: number = new Date().getFullYear()): Array<{ name: string; date: Date }> => {
	const days = sweden.days.map(f => ({
		name: f.name,
		date: f.producer(year),
	}));
	days.sort((a, b) => {
		return a.date.getTime() - b.date.getTime();
	});

	return days;
};

export const betweenDates = (date1: Date, date2: Date) => {
	const startYear = date1.getFullYear();
	const endYear = date2.getFullYear();

	let dates: Array<{ name: string; date: Date }> = [];

	if (startYear > endYear) throw new Error("End date is before start date");
	for (let year = startYear; year <= endYear; year++) {
		dates = [...dates, ...getDays(year)];
	}

	return dates.filter(d => d.date >= date1 && d.date <= date2);
};

export const dateIsPublicHoliday = (date: Date): boolean => {
	const year = date.getFullYear();

	const publicHolidays = getDays(year);

	const month = date.getMonth();
	const day = date.getDate();

	return !!publicHolidays.filter(ph => {
		const m = ph.date.getMonth();
		const d = ph.date.getDate();

		return month === m && day === d;
	}).length;
};
