import { NativeSelect, TextInput, rem } from "@mantine/core";

type Currency = {
	value: "SEK" | "EUR" | "USD";
	label: string;
};

const data: Array<Currency> = [
	{ value: "SEK", label: "🇸🇪 SEK" },
	{ value: "EUR", label: "🇪🇺 EUR" },
	{ value: "USD", label: "🇺🇸 USD" },
];

interface ICurrencyInput {
	onCurrencyChange: (currency: string) => void;
	onAmountChange: (amount: string) => void;
	currency: string;
	value: number;
}

export const CurrencyInput = ({ currency, value, onAmountChange, onCurrencyChange }: ICurrencyInput) => {
	return (
		<TextInput
			type="number"
			placeholder="1000"
			value={value}
			onChange={ev => {
				onAmountChange(ev.target.value);
			}}
			rightSection={
				<NativeSelect
					data={data}
					rightSectionWidth={28}
					value={currency}
					onChange={ev => onCurrencyChange(ev.target.value)}
					styles={{
						input: {
							fontWeight: 500,
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
							width: rem(88),
							marginRight: rem(-2),
						},
					}}
				/>
			}
			rightSectionWidth={88}
		/>
	);
};
