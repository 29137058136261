import { LineChart, type LineChartSeries } from "@mantine/charts";
import {
	eachMonthOfInterval,
	eachWeekendOfMonth,
	endOfMonth,
	format,
	getDaysInMonth,
	isSameDay,
	isSameMonth,
	startOfMonth,
	sub,
} from "date-fns";
import "@mantine/charts/styles.css";
import { useEffect, useState } from "react";
import type { OutputRegisteredTime, Project, User } from "../../generated";
import { getUsers } from "../../api/users";
import { getRegisteredTimeInPeriod } from "../../api/registered-times";
import Duration from "@icholy/duration";
import { Badge, Container, Paper, Select } from "@mantine/core";
import { getProjects } from "../../api/projects";
import { betweenDates } from "../../utils/ledig/public-holiday";

function djb2(str: string) {
	let hash = 5381;
	for (let i = 0; i < str.length; i++) {
		hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
	}
	return hash;
}

function hashStringToColor(str: string) {
	const hash = djb2(str);
	const r = (hash & 0xff0000) >> 16;
	const g = (hash & 0x00ff00) >> 8;
	const b = hash & 0x0000ff;
	return `#${`0${r.toString(16)}`.substr(-2)}${`0${g.toString(16)}`.substr(-2)}${`0${b.toString(16)}`.substr(-2)}`;
}

const getWorkHoursInMonth = (m: Date) => {
	const weekends = eachWeekendOfMonth(m);
	const holidays = betweenDates(startOfMonth(m), endOfMonth(m));

	const nonWorkingDays = [
		...weekends,
		...holidays.filter(h => !weekends.find(w => isSameDay(w, h.date))).map(h => h.date),
	];

	const workDaysInMonth = getDaysInMonth(m) - nonWorkingDays.length;
	return workDaysInMonth * 8;
};

const to = endOfMonth(sub(new Date(), { months: 1 }));
const from = sub(to, { years: 1 });

type DisplayType = "invoicing_rate" | "hours";

const isDisplayType = (str: unknown): str is DisplayType => {
	return str === "invoicing_rate" || str === "hours";
};

export const Statistics = () => {
	const [userReports, setUserReports] = useState<Array<OutputRegisteredTime>>([]);
	const [users, setUsers] = useState<Array<User>>([]);
	const [showUsers, setShowUsers] = useState<string[]>([]);
	const [showProjects, setShowProjects] = useState<string[]>([]);
	const [projects, setProjects] = useState<Array<Project>>([]);
	const [displayType, setDisplayType] = useState<DisplayType>("invoicing_rate");

	const [series, setSeries] = useState<LineChartSeries[]>();
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const [data, setData] = useState<Record<string, any>[]>();

	useEffect(() => {
		getRegisteredTimeInPeriod(from, to).then(setUserReports);
		getUsers().then(setUsers);
		getProjects().then(setProjects);
	}, []);

	useEffect(() => {
		if (userReports.length < 1) return;

		const months = eachMonthOfInterval({
			start: from,
			end: to,
		});

		const data = months.map(m => {
			const workHoursInMonth = getWorkHoursInMonth(m);

			const reps = userReports.reduce<Record<string, number>>(
				(acc, curr) => {
					if (!showProjects.find(sp => sp === curr.project_id)) return acc;
					if (!showUsers.find(su => su === curr.user_id)) return acc;
					if (!isSameMonth(m, new Date(curr.date))) return acc;

					const duration = new Duration(curr.time);

					if (!acc[curr.user_id]) acc[curr.user_id] = 0;

					acc[curr.user_id] = +acc[curr.user_id] + duration.hours();
					acc.avg = acc.avg + duration.hours();

					return acc;
				},
				{
					avg: 0,
				},
			);

			reps.avg = reps.avg / (Object.keys(reps).length - 1);

			if (displayType === "invoicing_rate") {
				for (const key in reps) {
					reps[key] = +(reps[key] / workHoursInMonth).toFixed(2) * 100;
				}
			}

			return {
				date: format(m, "MMM"),
				...reps,
			};
		});

		setData(data);
	}, [userReports, showUsers, showProjects, displayType]);

	useEffect(() => {
		setSeries([
			...users.map(u => ({
				name: u.id,
				color: hashStringToColor(u.id),
				label: u.name,
			})),
			{
				name: "avg",
				color: "#ff00ff",
				label: "Average",
			},
		]);

		setShowUsers(users.map(u => u.id));
	}, [users]);

	useEffect(() => {
		setShowProjects(projects.filter(p => p.customer?.name !== "Virki AB").map(p => p.id));
	}, [projects]);

	if (!series || !data) return;

	return (
		<Container fluid p="xl">
			<Select
				data={[
					{ value: "invoicing_rate", label: "Faktureringsgrad" },
					{ value: "hours", label: "Timmar" },
				]}
				value={displayType}
				onChange={value => {
					if (isDisplayType(value)) setDisplayType(value);
				}}
			/>
			<Paper bg="#333" shadow="xs" p="xl">
				<div
					style={{
						paddingBottom: "20px",
					}}
				>
					{users.map(user => {
						return (
							<Badge
								key={user.id}
								style={{ cursor: "pointer" }}
								color={hashStringToColor(user.id)}
								variant={showUsers.includes(user.id) ? "filled" : "outline"}
								onClick={() => {
									if (showUsers.includes(user.id)) setShowUsers(showUsers.filter(u => u !== user.id));
									else setShowUsers([...showUsers, user.id]);
								}}
							>
								{user.name}
							</Badge>
						);
					})}
				</div>
				<LineChart
					h={300}
					valueFormatter={value => `${value}${displayType === "invoicing_rate" ? "%" : "h"}`}
					data={data}
					xAxisLabel="Date"
					yAxisLabel="Billable time billed"
					dataKey="date"
					yAxisProps={{ domain: [0, 100] }}
					series={series}
					curveType="monotone"
				/>
			</Paper>
		</Container>
	);
};
