import { useEffect, useState } from "react";
import type { Contact as APIContact } from "../../../generated";
import { Button, Container, Flex, Table } from "@mantine/core";
import { format } from "date-fns";
import { getContactsByCustomerId } from "../../../api/contacts";
import { Link, useParams } from "react-router-dom";
import { Contact } from "./contact";

export const Contacts = () => {
	const [contacts, setContacts] = useState<APIContact[]>();
	const { page, customerId, pageArg } = useParams();

	useEffect(() => {
		if (!customerId) return;

		getContactsByCustomerId(customerId).then(setContacts);
	}, [customerId, pageArg]);

	if (pageArg) return <Contact contactId={pageArg === "new" ? undefined : pageArg} />;

	return (
		<Container>
			<Flex justify="end">
				<Button component={Link} to={`/customersv2/${customerId}/${page}/new`}>
					New
				</Button>
			</Flex>
			<Table>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>Name</Table.Th>
						<Table.Th>Created at</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{contacts?.map(contact => (
						<Table.Tr key={contact.id}>
							<Table.Td>
								<Link to={`/customersv2/${customerId}/${page}/${contact.id}`}>{contact.name}</Link>
							</Table.Td>
							<Table.Td>{format(contact.created_at, "yyyy-MM-dd")}</Table.Td>
						</Table.Tr>
					))}
				</Table.Tbody>
			</Table>
		</Container>
	);
};
