import { format } from "date-fns";
import _ from "lodash";
import { timeFetch } from ".";
import type { AssignedProject, InputAssignedProjectsPost, InputAssignedProjectsPut, User } from "../generated";
import { API_BASE_URL } from ".";

export const isBillingType = (
	billingType?: string | null,
): billingType is InputAssignedProjectsPost["customer_billing_type"] => {
	switch (billingType) {
		case "hourly":
		case "fixed":
			return true;
		default:
			return false;
	}
};

export const postAssignProjects = (input: InputAssignedProjectsPost) =>
	timeFetch<string>(`${API_BASE_URL}/api/v1/assigned_projects`, {
		method: "POST",
		body: JSON.stringify(
			_.pickBy(
				{
					...input,
					start_date: format(input.start_date, "yyyy-MM-dd"),
					end_date: input.end_date ? format(input.end_date, "yyyy-MM-dd") : null,
				},
				_.identity,
			),
		),
	});

export const putAssignProjects = (input: InputAssignedProjectsPut) =>
	timeFetch<string>(`${API_BASE_URL}/api/v1/assigned_projects/${input.id}`, {
		method: "PUT",
		body: JSON.stringify(
			_.pickBy(
				{
					...input,
					start_date: format(input.start_date, "yyyy-MM-dd"),
					end_date: input.end_date === "infinity" ? "infinity" : format(input.end_date, "yyyy-MM-dd"),
				},
				_.identity,
			),
		),
	});

export const getUsersAssignedToProject = (projectId: string) =>
	timeFetch<Array<User>>(`${API_BASE_URL}/api/v1/projects/${projectId}/users`, { method: "GET" });

export const getUsersAvailableForProject = (projectId: string) =>
	timeFetch<Array<User>>(`${API_BASE_URL}/api/v1/projects/${projectId}/available_users`, { method: "GET" });

export const getAssignedProjectsAssignedForUser = (userId: string) =>
	timeFetch<Array<AssignedProject>>(`${API_BASE_URL}/api/v1/users/${userId}/projects`, { method: "GET" });

export const deleteAssignProjects = (id: string) =>
	timeFetch<void>(`${API_BASE_URL}/api/v1/assigned_projects/${id}`, {
		method: "DELETE",
	});

export const hasRegisteredTime = (id: string) =>
	timeFetch<boolean>(`${API_BASE_URL}/api/v1/assigned_projects/${id}/has_registered_time`, { method: "GET" });

export const getExpiringAssignedProjects = () =>
	timeFetch<AssignedProject[]>(`${API_BASE_URL}/api/v1/assigned_projects/expiring`, {
		method: "GET",
	});
