import { TextInput } from "@mantine/core";
import { registerTime } from "../../../api/registered-times";
import { format } from "date-fns";
import type { Project } from "../../../generated";

import classes from "../project-row.module.css";
import { setReportedTime } from "../store";

type Props = {
	value: string;
	date: Date;
	disabled: boolean;
	project: Project;
};

export const TimeInput = ({ value, date, disabled, project }: Props) => {
	const handleChange = (date: Date, time: number) => {
		if (Number.isNaN(time) || time > 24) return;

		registerTime({
			date: format(date, "yyyy-MM-dd"),
			project: project.id,
			time: `${time}h`,
		}).then(registeredTime => {
			setReportedTime(registeredTime);
		});
	};

	return (
		<TextInput
			key={date.getTime()}
			value={value}
			max={24}
			min={0}
			onChange={v => handleChange(date, Number(v.target.value))}
			onFocus={ev => ev.target.select()}
			classNames={{ input: classes.input }}
			className={classes.date}
			wrapperProps={{
				"data-date": format(date, "MMM d"),
			}}
			opacity={disabled ? 0.7 : 1}
			disabled={disabled}
		/>
	);
};
