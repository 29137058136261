import { format, isSameDay } from "date-fns";
import { Input, Text } from "@mantine/core";
import type { ViewDate, ViewProject } from "./types";
import Duration from "@icholy/duration";
import { useCurrentViewport } from "../../hooks/use-current-viewport";

type ISummaryRow = {
	dateRange: ViewDate[];
	viewProjects: ViewProject[];
};

export const SummaryRow = ({ dateRange, viewProjects }: ISummaryRow) => {
	const { smallScreen } = useCurrentViewport();

	const totalTime = viewProjects.reduce((acc, curr) => {
		return acc + curr.reported_times.map(rt => new Duration(rt.time).hours()).reduce((a, c) => a + c, 0);
	}, 0);

	return (
		<>
			<div style={{ paddingRight: "var(--mantine-spacing-lg)" }}>
				<Text>Totalt</Text>
			</div>
			{dateRange.map(viewDate => {
				/* Gets all the reported hours for current viewDate in the ViewProjects */
				const reportedTime = viewProjects.reduce((acc, curr) => {
					const currentDayReports = curr.reported_times.filter(rt => isSameDay(rt.date, viewDate.date));
					return (
						acc + currentDayReports.map(cdr => new Duration(cdr.time).hours()).reduce((a, c) => a + c, 0)
					);
				}, 0);

				return (
					<div
						style={{
							height: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center",
						}}
						key={format(viewDate.date, "dd")}
					>
						<Input
							disabled={true}
							styles={{
								input: {
									textAlign: "center",
									paddingInlineStart: "calc(var(--input-padding-inline-start) / 2)",
									paddingInlineEnd: "calc(var(--input-padding-inline-start) / 2)",
									width: "2.2em",
								},
							}}
							value={reportedTime}
						/>
					</div>
				);
			})}
			{!smallScreen && (
				<>
					<div
						style={{ paddingLeft: "var(--mantine-spacing-sm)", paddingRight: "var(--mantine-spacing-sm)" }}
					>
						{totalTime}
					</div>
					<div />
					<div />
				</>
			)}
		</>
	);
};
