import { isAfter } from "date-fns";
import { type ReactNode, createContext, useContext, useEffect, useReducer, useCallback } from "react";
import { doLoginSuccess, doLogoutSuccess, initialAuthState } from "./action";
import { authReducer, parseToken } from "./reducer";

export type UserData = {
	Extensions: unknown;
	Groups: Array<string>;
	ID: string;
	Name: string;
	aud: Array<string>;
	exp: number;
	iat: number;
	nbf: number;
	sub: boolean;
};

export type AuthStateType = {
	isAuthenticated?: boolean;
	user?: UserData;
	token?: string;
	error?: string;
	fetching?: boolean;
};

export type AuthContextType = {
	state: AuthStateType;
	login: (token: string) => void;
	logout: () => void;
};

const Store = createContext<AuthContextType>({
	state: {},
	login: () => {},
	logout: () => {},
});

export const useAuth = () => useContext(Store);

interface IAuthProvider {
	children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProvider) => {
	const [authState, dispatch] = useReducer(authReducer, initialAuthState);

	const login = useCallback((token: string) => {
		localStorage.setItem("token", token);
		dispatch(doLoginSuccess(token));
	}, []);
	const logout = useCallback(() => {
		localStorage.removeItem("token");
		dispatch(doLogoutSuccess());
	}, []);

	const hasExpired = useCallback((token: string) => {
		const user = parseToken(token);
		const expDate = new Date(user.exp * 1000);

		if (isAfter(new Date(), expDate)) return true;

		return false;
	}, []);

	const checkAuthState = () => {
		if (!authState.token) return;

		if (hasExpired(authState.token)) {
			logout();
		}
	};

	useEffect(() => {
		const authCheckInterval = setInterval(checkAuthState, 10000);

		return () => clearInterval(authCheckInterval);
	}, [checkAuthState]);

	useEffect(() => {
		const token = localStorage.getItem("token");

		if (token && !hasExpired(token)) {
			login(token);
		} else {
			logout();
		}
	}, [hasExpired, login, logout]);

	const opsAndState = {
		state: authState,
		login,
		logout,
	};

	return <Store.Provider value={opsAndState}>{children}</Store.Provider>;
};
